import { setupWorker } from 'msw'
import { Country } from '@hfs/types/locale'
import { handlers } from './handlers'

type Param = {
  baseUrl: string
  country: Country
}

export const worker = ({ baseUrl, country }: Param) =>
  setupWorker(...handlers.flatMap(handler => handler({ baseUrl: `${baseUrl}/${country.toLowerCase()}` })))
