type Route =
  | '*'
  | '/'
  | '/about-us'
  | '/contact-us'
  | '/contracts-and-rules'
  | '/invest'
  | '/news'
  | '/privacy-policy'
  | '/rooms'
  | '/rooms/:roomUuid'
  | '/rooms/:roomUuid/description'
  | '/rooms/:roomUuid/request-maker/checkout'
  | '/rooms/:roomUuid/request-maker/date'
  | '/rooms/:roomUuid/request-maker/user-information'
  | '/rooms/:roomUuid/request-maker/payment-information'
  | '/rooms/:roomUuid/request-maker/success'
  | '/sitemap'
  | '/tos'

export const getRoute = (route: Route) => route
