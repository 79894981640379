import { Route, Switch } from '@hfs/react-router'
import { getRoute } from '@hfs/website/utils/getRoute'
import { AboutUsRoutes } from './AboutUs'
import { ContactUsRoutes } from './ContactUs'
import { ContractsAndRulesRoutes } from './ContractsAndRules'
import { HomepageRoutes } from './Homepage'
import { InvestRoutes } from './Invest'
import { NewsRoutes } from './News'
import { NoMatchRoutes } from './NoMatch'
import { PrivacyPolicyRoutes } from './PrivacyPolicy'
import { RoomsRoutes } from './Rooms'
import { SitemapRoutes } from './Sitemap'
import { ToSRoutes } from './ToS'

export const Routes = () => (
  <Switch>
    <Route component={HomepageRoutes} exact path={getRoute('/')} />
    <Route component={AboutUsRoutes} path={getRoute('/about-us')} />
    <Route component={ContactUsRoutes} path={getRoute('/contact-us')} />
    <Route component={ContractsAndRulesRoutes} path={getRoute('/contracts-and-rules')} />
    <Route component={InvestRoutes} path={getRoute('/invest')} />
    <Route component={NewsRoutes} path={getRoute('/news')} />
    <Route component={PrivacyPolicyRoutes} path={getRoute('/privacy-policy')} />
    <Route component={RoomsRoutes} path={getRoute('/rooms')} />
    <Route component={SitemapRoutes} path={getRoute('/sitemap')} />
    <Route component={ToSRoutes} path={getRoute('/tos')} />
    <Route component={NoMatchRoutes} path={getRoute('*')} />
  </Switch>
)
