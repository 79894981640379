/* eslint-disable max-len */
import { RoomType } from '@hfs/types/rooms'
import { RoomDecoded } from '@hfs/website/api/resources/rooms/room'

export const room: RoomDecoded = {
  condominiumBill: false,
  depositPrice: 20000,
  description:
    'Dolor mollit ad tempor velit aute nulla sunt amet do ea et ullamco. Ut laborum irure dolor sint ipsum. Enim deserunt occaecat nostrud consectetur. Consequat proident id commodo exercitation tempor proident officia id sunt quis.',
  electricityBill: true,
  gasBill: false,
  house: {
    address: 'Via del Pascolo, 65',
    area: {
      description:
        'Dolor anim non aliqua cupidatat qui nisi consectetur sunt commodo nostrud elit. Sunt ea eu incididunt aute qui do pariatur ex. Duis qui sunt minim et et non tempor.',
      interestPoints: [
        {
          description: 'Ut culpa laboris nisi nostrud Lorem Lorem est Lorem commodo mollit amet.',
          latitude: 41.9010333,
          longitude: 12.4810843,
          name: 'Fontana di Trevi',
        },
        {
          description: 'Ut culpa laboris nisi nostrud Lorem Lorem est Lorem commodo mollit amet.',
          latitude: 41.9006469,
          longitude: 12.4789498,
          name: 'Piazza Navona',
        },
        {
          description: 'Ut culpa laboris nisi nostrud Lorem Lorem est Lorem commodo mollit amet.',
          latitude: 41.9000967,
          longitude: 12.4783954,
          name: 'Foro Romano',
        },
      ],
      name: 'Eur',
    },
    commonZones: [{ description: 'Eu minim culpa veniam eu sunt.', icon: 'kitchen', name: 'Cucina' }],
    latitude: 42.0234,
    longitude: 13.0094,
    photos: [
      {
        alt: 'Culpa ex cillum nisi aliqua minim duis ad dolore.',
        mimetypes: [
          {
            extension: 'jpeg',
            name: 'image/jpeg',
          },
          {
            extension: 'webp',
            name: 'image/webp',
          },
        ],
        position: 0,
        uuid: 'e06f4484-2141-4a0d-adc6-ba35af01b402',
      },
      {
        alt: 'Adipisicing consectetur cupidatat pariatur cillum ut enim eiusmod qui tempor culpa qui labore culpa.',
        mimetypes: [
          {
            extension: 'jpeg',
            name: 'image/jpeg',
          },
          {
            extension: 'webp',
            name: 'image/webp',
          },
        ],
        position: 1,
        uuid: '48a5a375-f33b-4b9f-85f0-7e7fa7608de8',
      },
      {
        alt:
          'Voluptate consequat excepteur ad aliqua non qui cillum ea exercitation fugiat exercitation sint proident. Qui voluptate duis ad cillum enim eu qui occaecat adipisicing voluptate veniam aliquip pariatur. Id id dolore sit veniam. Nisi amet et dolore est sit veniam aliqua laborum mollit incididunt ad.',
        mimetypes: [
          {
            extension: 'jpeg',
            name: 'image/jpeg',
          },
          {
            extension: 'webp',
            name: 'image/webp',
          },
        ],
        position: 2,
        uuid: '4a32b492-bf36-4114-96ea-2a84f683e16a',
      },
    ],
    services: [
      {
        description: 'Quis labore commodo qui deserunt ut dolore laborum culpa ad velit.',
        icon: 'laundry',
        name: 'Lavanderia',
      },
    ],
    tags: [
      {
        description: 'Magna sint ea enim veniam nulla amet laborum aliqua sunt.',
        name: 'top',
      },
    ],
  },
  internetBill: false,
  name: 'Stanza farlocca 4',
  photos: [
    {
      alt:
        'Nisi in nostrud minim ullamco et ea consequat duis. Dolore laborum proident commodo in. Sit sit anim sit quis fugiat aliquip reprehenderit ea minim elit culpa.',
      mimetypes: [
        {
          extension: 'jpeg',
          name: 'image/jpeg',
        },
        {
          extension: 'webp',
          name: 'image/webp',
        },
      ],
      position: 0,
      uuid: '4ab00822-4d29-4b2d-9b3c-8058fab98cd0',
    },
    {
      alt:
        'Excepteur deserunt deserunt adipisicing aliqua deserunt exercitation adipisicing ea incididunt velit mollit duis.',
      mimetypes: [
        {
          extension: 'jpeg',
          name: 'image/jpeg',
        },
        {
          extension: 'webp',
          name: 'image/webp',
        },
      ],
      position: 1,
      uuid: '5943c2ba-4784-43bd-be64-edf67082bcda',
    },
    {
      alt: 'Esse anim cupidatat ex nostrud ipsum eiusmod esse nostrud ut irure.',
      mimetypes: [
        {
          extension: 'jpeg',
          name: 'image/jpeg',
        },
        {
          extension: 'webp',
          name: 'image/webp',
        },
      ],
      position: 2,
      uuid: '85bdc743-5b16-4180-9091-cea4bba2d98c',
    },
    {
      alt: 'Occaecat irure irure do Lorem fugiat pariatur ad magna in non culpa ipsum duis.',
      mimetypes: [
        {
          extension: 'jpeg',
          name: 'image/jpeg',
        },
        {
          extension: 'webp',
          name: 'image/webp',
        },
      ],
      position: 3,
      uuid: '7536ca87-2f38-41f0-ba19-1289b6e26758',
    },
  ],
  price: 50000,
  roomExtras: [
    {
      description: 'Deserunt ut irure ad consectetur.',
      name: 'TV Plasma 50"',
    },
  ],
  roomServices: [
    {
      description: 'Deserunt ut irure ad consectetur.',
      name: 'TV Plasma 50"',
    },
  ],
  roomType: RoomType.single,
  size: 15,
  tags: [
    {
      description: 'Magna sint ea enim veniam nulla amet laborum aliqua sunt.',
      name: 'top',
    },
  ],
  uuid: 'be1bc35b-bad7-4965-9216-b31a372aaa46',
  wasteBill: false,
  waterBill: true,
}
