import { ReactNode, useMemo } from 'react'
import { Text, TTypographyProps } from '@hfs/ui/components/Text'
import { TColorName } from '@hfs/ui/tokens/colors'

type CssProperties<Values> = Record<HeadingType, Values>

const fontSize: CssProperties<TTypographyProps['typographyFontSize']> = {
  blockquote: 24,
  display: 56,
  h1: 56,
  h2: 32,
  h3: 24,
  h4: 18,
  h5: 16,
  h6: 14,
}

const lineHeight: CssProperties<TTypographyProps['typographyLineHeight']> = {
  blockquote: 32,
  display: 60,
  h1: 60,
  h2: 38,
  h3: 32,
  h4: 28,
  h5: 24,
  h6: 20,
}

const fontWeight: CssProperties<TTypographyProps['typographyFontWeight']> = {
  blockquote: 700,
  display: 500,
  h1: 500,
  h2: 500,
  h3: 500,
  h4: 500,
  h5: 500,
  h6: 500,
}

const getHeadingProps = (type: HeadingType): InjectedHeadingProps => ({
  typographyFontSize: fontSize[type],
  typographyFontWeight: fontWeight[type],
  typographyLineHeight: lineHeight[type],
})

type HeadingType = 'blockquote' | 'display' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type InjectedHeadingProps = Pick<
  TTypographyProps,
  'typographyFontSize' | 'typographyFontWeight' | 'typographyLineHeight'
>

type Props = Omit<TTypographyProps, keyof InjectedHeadingProps> & {
  colorName?: TColorName
  children: ReactNode
  type: HeadingType
}

export const Heading = ({ children, type, ...props }: Props) => {
  const headingProps = useMemo(() => getHeadingProps(type), [type])

  return (
    <Text {...headingProps} {...props}>
      {children}
    </Text>
  )
}

export type HeadingProps = Props
