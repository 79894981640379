/* eslint-disable no-nested-ternary */
import { css } from 'styled-components'
import { colors, ghostColors, TColorName } from '@hfs/ui/tokens/colors'
import { TSpacing } from '@hfs/ui/tokens/spacing'

type BorderWidthSize = 0 | 1 | 2 | 3

type BorderStyle =
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'groove'
  | 'hidden'
  | 'inherit'
  | 'initial'
  | 'inset'
  | 'none'
  | 'ridge'
  | 'outset'
  | 'solid'
  | 'unset'

type Param = {
  borderBottomWidth?: BorderWidthSize
  borderColorName?: TColorName
  borderLeftWidth?: BorderWidthSize
  borderRadius?: TSpacing
  borderRadiusBottomLeft?: TSpacing
  borderRadiusBottomRight?: TSpacing
  borderRadiusTopLeft?: TSpacing
  borderRadiusTopRight?: TSpacing
  borderRightWidth?: BorderWidthSize
  borderStyle?: BorderStyle
  borderTopWidth?: BorderWidthSize
  borderWidth?: BorderWidthSize
  ghost?: boolean
}

const getSize = (border?: number | string) => {
  if (typeof border === 'undefined') {
    return undefined
  }

  if (typeof border === 'number') {
    return `${border}px`
  }

  return border
}

const hideOverflow = ({
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  borderRadiusTopLeft,
  borderRadiusTopRight,
}: Param) => {
  if (
    borderRadius ||
    borderRadiusBottomLeft ||
    borderRadiusBottomRight ||
    borderRadiusTopLeft ||
    borderRadiusTopRight
  ) {
    return css`
      overflow: hidden;
    `
  }

  return undefined
}

export const border = ({
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  borderRadiusTopLeft,
  borderRadiusTopRight,
  borderColorName,
  borderStyle,
  borderWidth,
  borderBottomWidth,
  borderLeftWidth,
  borderRightWidth,
  borderTopWidth,
  ghost,
}: Param) => css`
  border-width: ${getSize(borderWidth)};
  border-bottom-width: ${getSize(borderBottomWidth)};
  border-left-width: ${getSize(borderLeftWidth)};
  border-right-width: ${getSize(borderRightWidth)};
  border-top-width: ${getSize(borderTopWidth)};

  border-radius: ${getSize(borderRadius)};
  border-bottom-left-radius: ${getSize(borderRadiusBottomLeft)};
  border-bottom-right-radius: ${getSize(borderRadiusBottomRight)};
  border-top-left-radius: ${getSize(borderRadiusTopLeft)};
  border-top-right-radius: ${getSize(borderRadiusTopRight)};

  border-color: ${borderColorName ? (ghost ? ghostColors[borderColorName] : colors[borderColorName]) : 'transparent'};
  border-style: ${borderStyle};

  ${hideOverflow}
`

export type TBorderParams = Param
