import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { breakpoints } from '@hfs/ui/tokens/breakpoints'
import { throttle } from '@hfs/utils/functions/throttle'

type State = {
  clientHeight: number
  clientWidth: number
}

const isMobile = ({ clientWidth }: State) => clientWidth >= breakpoints.mobile

const isTablet = ({ clientWidth }: State) => clientWidth >= breakpoints.tablet

const isDesktop = ({ clientWidth }: State) => clientWidth > breakpoints.desktop

const Context = createContext<State>({ clientHeight: 0, clientWidth: 0 })

export const DimensionsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, setState] = useState<State>({
    clientHeight: document.documentElement.clientHeight,
    clientWidth: document.documentElement.clientWidth,
  })

  useEffect(() => {
    const eventListener = throttle(() => {
      const { clientHeight: currentClientHeight, clientWidth: currentClientWidth } = document.documentElement

      setState({
        clientHeight: currentClientHeight,
        clientWidth: currentClientWidth,
      })
    }, 300)

    window.addEventListener('resize', eventListener)

    return () => {
      eventListener.cancel()

      window.removeEventListener('resize', eventListener)
    }
  }, [setState])

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export const useViewport = () => {
  const state = useContext(Context)

  return {
    isDesktop: isDesktop(state),
    isMobile: isMobile(state),
    isMobileOnly: !isTablet(state),
    isTablet: isTablet(state),
    isTabletOnly: isTablet(state) && !isDesktop(state),
  }
}
