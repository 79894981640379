import { lazy } from 'react'
import { Route, Switch } from '@hfs/react-router'
import { getRoute } from '@hfs/website/utils/getRoute'

export const ContractsAndRulesRoutes = () => (
  <Switch>
    <Route
      component={lazy(async () => {
        const { ContractsAndRules } = await import(
          // eslint-disable-next-line max-len
          /* webpackPrefetch: true, webpackChunkName: "ContractsAndRules" */ '@hfs/website/react/pages/public/ContractsAndRules'
        )

        return {
          default: ContractsAndRules,
        }
      })}
      exact
      path={getRoute('/contracts-and-rules')}
    />
  </Switch>
)
