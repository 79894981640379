export const spacing = [
  0,
  2,
  4,
  8,
  12,
  16,
  20,
  24,
  28,
  32,
  36,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  68,
  72,
  76,
  78,
  82,
  86,
  90,
  94,
  98,
  102,
  104,
  128,
  164,
  200,
] as const

export type TSpacing = typeof spacing[number] | string
