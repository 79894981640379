import { lazy } from 'react'
import { Route, Switch } from '@hfs/react-router'
import { getRoute } from '@hfs/website/utils/getRoute'

export const AboutUsRoutes = () => (
  <Switch>
    <Route
      component={lazy(async () => {
        const { AboutUs } = await import(
          /* webpackPrefetch: true, webpackChunkName: "AboutUs" */ '@hfs/website/react/pages/public/AboutUs'
        )

        return {
          default: AboutUs,
        }
      })}
      exact
      path={getRoute('/about-us')}
    />
  </Switch>
)
