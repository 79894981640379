import { forwardRef } from 'react'
import styled from 'styled-components'

const BaseImage = styled(styled.img<Pick<Props, 'height' | 'width'>>``).withConfig<Pick<Props, 'height' | 'width'>>({
  shouldForwardProp: prop => !['className', 'height', 'width'].includes(prop),
})`
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  text-indent: 100%;
  vertical-align: middle;
  white-space: nowrap;

  ${({ onClick }) => onClick && 'cursor: pointer;'}

  ${({ height }) => {
    if (!height) {
      return 'height: auto;'
    }

    if (typeof height === 'string') {
      return `height: ${height};`
    }

    return `height: ${height}px;`
  }}

  ${({ width }) => {
    if (!width) {
      return ''
    }

    if (typeof width === 'string') {
      return `width: ${width};`
    }

    return `width: ${width}px;`
  }}
`

type Props = {
  alt?: string
  className?: string
  height?: number | string
  onClick?: () => void
  src: string
  width?: number | string
}

export const Image = forwardRef<HTMLImageElement, Props>(
  ({ alt, className, height, onClick, src, width }: Props, ref) => (
    <BaseImage ref={ref} alt={alt} className={className} height={height} onClick={onClick} src={src} width={width} />
  )
)
