import { PropsWithChildren } from 'react'
import { IntlProvider as BaseIntlProvider } from '@hfs/i18n/components/IntlProvider'
import { TranslationValues } from '@hfs/i18n/types'
import { Country, Language } from '@hfs/types/locale'

const getTranslations = async (locale: string): Promise<TranslationValues> => ({
  ...(
    await import(
      // eslint-disable-next-line max-len
      /* webpackPrefetch: true,  webpackChunkName: "translations~i18n" */ `@hfs/ui/i18n/dist/translations.${locale}.json`
    )
  ).default,
  ...(
    await import(
      // eslint-disable-next-line max-len
      /* webpackPrefetch: true, webpackChunkName: "translations~website" */ `@hfs/website/i18n/dist/translations.${locale}.json`
    )
  ).default,
})

type Props = {
  country: Country
  language: Language
}

export const IntlProvider = ({ children, country, language }: NonNullable<PropsWithChildren<Props>>) => (
  <BaseIntlProvider country={country} getTranslations={getTranslations} language={language}>
    {children}
  </BaseIntlProvider>
)
