/* eslint-disable max-len */
import { css } from 'styled-components'
import { colors } from '@hfs/ui/tokens/colors'
import { spacing } from '@hfs/ui/tokens/spacing'
import { fontFamily } from '@hfs/ui/tokens/typography'

const ns = '.DayPicker'
const nsBody = `${ns}-Body`
const nsCaption = `${ns}-Caption`
const nsDay = `${ns}-Day`
const nsFooter = `${ns}-Footer`
const nsInput = `${ns}-Input`
const nsMonth = `${ns}-Month`
const nsMonths = `${ns}-Months`
const nsNavButton = `${ns}-NavButton`
const nsTodayButton = `${ns}-TodayButton`
const nsWeek = `${ns}-Week`
const nsWeekNumber = `${ns}-WeekNumber`
const nsWeekday = `${ns}-Weekday`
const nsWeekdays = `${ns}-Weekdays`
const nsWeekdaysRow = `${ns}-WeekdaysRow`
const nsWrapper = `${ns}-wrapper`

export const reactDayPicker = css`
  ${ns} {
    display: inline-block;
    font-family: ${fontFamily};
    font-size: 1rem;

    &--interactionDisabled ${nsDay} {
      cursor: default;
    }
  }

  ${nsWrapper} {
    flex-direction: row;
    outline: none;
    padding-bottom: 1em;
    position: relative;
    user-select: none;
  }

  ${nsMonths} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${nsMonth} {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    margin-top: 1em;
    margin: 0 1em;
    user-select: none;
  }

  ${nsNavButton} {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    color: ${colors.n100};
    cursor: pointer;
    display: inline-block;
    height: 1.25em;
    left: auto;
    margin-top: 2px;
    outline: none;
    position: absolute;
    right: 1.5em;
    top: 0.5em;
    width: 1.25em;

    &:hover {
      opacity: 0.8;
    }

    &--prev {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
      margin-right: 1.5em;
    }

    &--next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
    }

    &--interactionDisabled {
      display: none;
    }
  }

  ${nsCaption} {
    display: table-caption;
    padding: 0.5em;
    text-align: left;

    & > div {
      font-size: 1.15em;
      font-weight: 500;
    }
  }

  ${nsWeekdays} {
    display: table-header-group;
    margin-top: 1em;
  }

  ${nsWeekdaysRow} {
    display: table-row;
  }

  ${nsWeekday} {
    color: ${colors.n100};
    display: table-cell;
    font-size: 0.875em;
    padding: 0.5em;
    text-align: center;

    & abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  }

  ${nsBody} {
    display: table-row-group;
  }

  ${nsWeek} {
    display: table-row;
  }

  ${nsDay} {
    border: none;
    border-bottom-color: ${colors.white};
    border-bottom-style: solid;
    border-bottom-width: ${spacing[2]};
    cursor: pointer;
    display: table-cell;
    outline: none;
    position: relative;
    padding: 0.5em;
    text-align: center;
    vertical-align: middle;

    &--from {
      background-color: ${colors.g600};
    }

    &--to {
      background-color: ${colors.r600};
    }

    &--today {
      color: ${colors.black};

      &${nsDay}--disabled {
        font-weight: 700;
      }
    }

    &--selected {
      color: ${colors.n900};
      z-index: 1;

      &${nsDay}--disabled {
        color: ${colors.n300};
        background-color: ${colors.n000};
      }

      &:not(${nsDay}--disabled) {
        font-weight: 900;

        &:not(${nsDay}--disabled):not(&${nsDay}--outside) {
          &::before {
            background-color: ${colors.n050};
            border-color: ${colors.n050};
            border-radius: 64px;
            border-style: solid;
            border-width: 1px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
          }
        }
      }

      &:not(${nsDay}--from):not(${nsDay}--to) {
        background-color: ${colors.n000};
      }

      &${nsDay}--from:not(&${nsDay}--outside) {
        background-color: transparent;
        color: ${colors.white};
        font-weight: 900;

        &::before {
          background-color: ${colors.g600};
          border-color: ${colors.g600};
          border-radius: 64px;
          border-style: solid;
          border-width: 1px;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }

        &::after {
          background-color: ${colors.n000};
          border-bottom-left-radius: 64px;
          border-top-left-radius: 64px;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -2;
        }
      }

      &${nsDay}--to:not(&${nsDay}--outside) {
        background-color: transparent;
        color: ${colors.white};
        font-weight: 900;

        &::before {
          background-color: ${colors.r600};
          border-color: ${colors.r600};
          border-radius: 64px;
          border-style: solid;
          border-width: 1px;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }

        &::after {
          background-color: ${colors.n000};
          border-bottom-right-radius: 64px;
          border-top-right-radius: 64px;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -2;
        }
      }
    }

    &--outside {
      color: ${colors.n100};
      cursor: default;
    }

    &--disabled {
      color: #dce0e0;
      cursor: default;
    }

    &--sunday {
      background-color: #f7f8f8;
    }

    &--sunday:not(&--today) {
      color: #dce0e0;
    }

    &--selected&--outside {
      background-color: transparent;
    }
  }

  ${nsWeekNumber} {
    border-right: 1px solid #eaecec;
    color: ${colors.n100};
    cursor: pointer;
    display: table-cell;
    font-size: 0.75em;
    min-width: 1em;
    padding: 0.5em;
    text-align: right;
    vertical-align: middle;
  }

  ${nsFooter} {
    padding-top: 0.5em;
  }

  ${nsTodayButton} {
    background-color: transparent;
    background-image: none;
    border: none;
    box-shadow: none;
    color: ${colors.r900};
    cursor: pointer;
    font-size: 0.875em;
  }

  ${nsInput} {
    display: inline-block;

    &-OverlayWrapper {
      position: relative;
    }

    &-Overlay {
      background: ${colors.white};
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      left: 0;
      position: absolute;
      z-index: 1;
    }
  }
`
