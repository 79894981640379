import { lazy } from 'react'
import { Route, Switch } from '@hfs/react-router'
import { getRoute } from '@hfs/website/utils/getRoute'

export const ToSRoutes = () => (
  <Switch>
    <Route
      component={lazy(async () => {
        const { ToS } = await import(
          /* webpackPrefetch: true, webpackChunkName: "News" */ '@hfs/website/react/pages/public/ToS'
        )

        return {
          default: ToS,
        }
      })}
      exact
      path={getRoute('/tos')}
    />
  </Switch>
)
