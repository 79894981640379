import { lazy } from 'react'
import { Route, Switch } from '@hfs/react-router'
import { getRoute } from '@hfs/website/utils/getRoute'

export const ContactUsRoutes = () => (
  <Switch>
    <Route
      component={lazy(async () => {
        const { ContactUs } = await import(
          /* webpackPrefetch: true, webpackChunkName: "ContactUs" */ '@hfs/website/react/pages/public/ContactUs'
        )

        return {
          default: ContactUs,
        }
      })}
      exact
      path={getRoute('/contact-us')}
    />
  </Switch>
)
