import { rest } from 'msw'

type Param = {
  baseUrl: string
}

export const book = ({ baseUrl }: Param) => [
  rest.post(`${baseUrl}/api/v1/book/stripe/rooms/:roomUuid`, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
]
