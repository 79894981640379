import { rest } from 'msw'
import { rooms } from '@hfs/website/mocks/rooms'

type Param = {
  baseUrl: string
}

export const search = ({ baseUrl }: Param) => [
  rest.get(`${baseUrl}/api/v1/search/rooms`, (req, res, ctx) => {
    // TODO Implement search
    const searchedRooms = rooms

    return res(ctx.status(200), ctx.json(searchedRooms))
  }),
  rest.get(`${baseUrl}/api/v1/search/rooms/:uuid`, (req, res, ctx) => {
    const { uuid } = req.params

    const room = rooms.find(currentRoom => currentRoom.uuid === uuid)

    if (!room) {
      return res(ctx.status(404))
    }

    return res(ctx.status(200), ctx.json(room))
  }),
]
