import 'react-toastify/dist/ReactToastify.css'
import 'leaflet/dist/leaflet.css'
import './polyfills/leaflet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { render } from 'react-dom'
import { getApiEndpoint } from '@hfs/configuration/apiEndpoint'
import { environment } from '@hfs/configuration/environment'
import { serverEnvironment } from '@hfs/configuration/serverEnvironment'
import { Country } from '@hfs/types/locale'
import { App } from './App'
import { Config } from './types/config'

library.add(fab as any)
library.add(fas)

const initialiseMsw = ({ baseUrl, country }: { baseUrl: string; country: Country }) => {
  if (environment.development && process.env.MSW) {
    return require('./msw').worker({ baseUrl, country }).start()
  }

  return Promise.resolve()
}

export default class AppSetup {
  constructor(private appContainerId: string, private config: Config) {}

  setup() {
    const baseUrl = getApiEndpoint(serverEnvironment)
    const { country, language } = this.config

    initialiseMsw({ baseUrl, country }).then(() => {
      render(<App config={{ baseUrl, country, language }} />, document.getElementById(this.appContainerId))
    })
  }
}

;(window as any).PackageManager = AppSetup
