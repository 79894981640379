import { hexToRgba } from '@hfs/ui/utils/functions/hexToRgba'

export const colors = {
  b050: '#AED7FC',
  b100: '#83BAFA',
  b300: '#579EF7',
  b600: '#2C81F5',
  b900: '#0065F2',
  black: '#000000',
  g050: '#7BF585',
  g100: '#67EA76',
  g300: '#54E066',
  g600: '#40D557',
  g900: '#2DCB48',
  n000: '#D7DADB',
  n050: '#CDD1D2',
  n100: '#9FA3A5',
  n300: '#727478',
  n600: '#44464B',
  n900: '#16181E',
  r050: '#cd7c7b',
  r100: '#c56765',
  r300: '#bd514f',
  r600: '#b53b39',
  r900: '#ad2624',
  transparent: 'rgba(255, 255, 255, 0)',
  white: '#FFFFFF',
  y050: '#FFF03B',
  y100: '#FFE72C',
  y300: '#FFDE1E',
  y600: '#FFD50F',
  y900: '#FFCC00',
}

export const colorsActive = {
  b050: '#83BAFA',
  b100: '#579EF7',
  b300: '#2C81F5',
  b600: '#0065F2',
  b900: '#0051D8',
  black: '#000000',
  g050: '#67EA76',
  g100: '#54E066',
  g300: '#40D557',
  g600: '#2DCB48',
  g900: '#24A93A',
  n000: '#CDD1D2',
  n050: '#9FA3A5',
  n100: '#727478',
  n300: '#44464B',
  n600: '#16181E',
  n900: '#12131A',
  r050: '#FB8860',
  r100: '#F86B49',
  r300: '#F64F31',
  r600: '#F43319',
  r900: '#D42914',
  transparent: 'rgba(255, 255, 255, 0)',
  white: '#FFFFFF',
  y050: '#FFE72C',
  y100: '#FFDE1E',
  y300: '#FFD50F',
  y600: '#FFCC00',
  y900: '#D6A300',
}

export const colorsHover = {
  b050: '#99C8FB',
  b100: '#6DACF9',
  b300: '#4190F6',
  b600: '#1673F3',
  b900: '#005BE5',
  black: '#000000',
  g050: '#71EF7D',
  g100: '#5EE56E',
  g300: '#4ADB5F',
  g600: '#37D050',
  g900: '#29BA41',
  n000: '#E4E8E9',
  n050: '#B6BABC',
  n100: '#898C8F',
  n300: '#5B5D62',
  n600: '#2D2F35',
  n900: '#14161C',
  r050: '#FC966C',
  r100: '#FA7A55',
  r300: '#F75D3D',
  r600: '#F54125',
  r900: '#E42E17',
  transparent: 'rgba(255, 255, 255, 0)',
  white: '#FFFFFF',
  y050: '#FFEC34',
  y100: '#FFE325',
  y300: '#FFDA16',
  y600: '#FFD107',
  y900: '#EBB800',
}

const ghostifyColors = (colorsToGhostify: TColors) =>
  Object.entries(colorsToGhostify).reduce(
    (acc, [color, hex]) => ({
      ...acc,
      [color]: hexToRgba({ alpha: 0.16, hex }),
    }),
    {} as TColors
  )

export const ghostColors = ghostifyColors(colors)

export const ghostColorsActive = ghostifyColors(colorsActive)

export const ghostColorsHovers = ghostifyColors(colorsHover)

export const opacity = 0.6

export type TColorName = keyof typeof colors

export type TColors = { [name in TColorName]: string }
