import { ReactNode, useEffect, useState, useMemo } from 'react'
import { IntlProvider as IntlProviderBase } from 'react-intl'
import { TranslationValues } from '@hfs/i18n/types'
import { Country, Language } from '@hfs/types/locale'
import { noop } from '@hfs/utils/functions/noop'

type Props = {
  children: ReactNode
  country: Country
  getTranslations: (locale: string) => Promise<TranslationValues>
  language: Language
}

export const IntlProvider = ({ children, country, getTranslations, language }: Props) => {
  const locale = useMemo(() => `${language}-${country}`, [country, language])

  const [messages, setMessages] = useState<TranslationValues | undefined>()

  useEffect(() => {
    if (messages) {
      return
    }

    getTranslations(locale).then(setMessages).catch(noop)
  }, [getTranslations, locale, messages, setMessages])

  if (!messages) {
    return null
  }

  return (
    <IntlProviderBase locale={language} messages={messages}>
      {children}
    </IntlProviderBase>
  )
}
