import { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import { environment } from '@hfs/configuration/environment'
import { BrowserRouter } from '@hfs/react-router'
import { GlobalStyle } from '@hfs/ui/components/GlobalStyle'
import { DialogsProvider } from '@hfs/ui/contexts/Dialogs'
import { DimensionsProvider } from '@hfs/ui/hooks/useViewport'
import { FallbackLoader } from '@hfs/website/react/layout/FallbackLoader'
import { GdprBanner } from '@hfs/website/react/layout/GdprBanner'
import { ConfigProvider } from '@hfs/website/react/providers/Config'
import { IntlProvider } from '@hfs/website/react/providers/IntlProvider'
import { Routes } from './react/routes'
import { Config } from './types/config'

const client = new QueryClient()

type Props = {
  config: Config
}

export const App = ({ config }: Props) => {
  const basename = !environment.development ? `/${config.language}` : ''

  return (
    <DimensionsProvider>
      <GlobalStyle>
        <DialogsProvider>
          <QueryClientProvider client={client}>
            <IntlProvider country={config.country} language={config.language}>
              <ConfigProvider config={config}>
                <ToastContainer />
                <ReactQueryDevtools />

                <BrowserRouter basename={basename}>
                  <Suspense fallback={<FallbackLoader />}>
                    <GdprBanner />

                    <Routes />
                  </Suspense>
                </BrowserRouter>
              </ConfigProvider>
            </IntlProvider>
          </QueryClientProvider>
        </DialogsProvider>
      </GlobalStyle>
    </DimensionsProvider>
  )
}
